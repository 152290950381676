<script lang="ts" setup>
import { RouterLink, useRouter } from "vue-router";
import type { BoxLayout, DisplayMode } from "@shopware-pwa/composables-next";
import { useCmsTranslations } from "@shopware-pwa/composables-next";
import {
  buildUrlPrefix,
  getProductFromPrice,
  getProductName,
  getProductRoute,
  getSmallestThumbnailUrl,
} from "@shopware-pwa/helpers-next";
import { computed, ref, toRefs } from "vue";
import { useNotifications, useUrlResolver } from "#imports";
import { useElementSize } from "@vueuse/core";
import type { Schemas } from "~/api-types/storeApiTypes";
import { mdiArrowRight, mdiCalendarBlankOutline } from "@mdi/js";
import FundingProgress from "~/components/cms/components/FundingProgress.vue";

const props = withDefaults(
  defineProps<{
    product: Schemas["Product"];
    layoutType?: BoxLayout;
    isProductListing?: boolean;
    displayMode?: DisplayMode;
  }>(),
  {
    layoutType: "standard",
    displayMode: "standard",
    isProductListing: false,
  },
);

// type Translations = {
//   product: {
//     addedToWishlist: string;
//     reason: string;
//     cannotAddToWishlist: string;
//     addedToCart: string;
//     addToCart: string;
//     details: string;
//     investNow: string;
//   };
// };

// let translations: Translations = {
//   product: {
//     addedToWishlist: "has been added to wishlist.",
//     reason: "Reason",
//     cannotAddToWishlist: "cannot be added to wishlist.",
//     addedToCart: "has been added to cart.",
//     addToCart: "Add to cart",
//     details: "Details",
//   },
// };
//
// translations = defu(translations, useCmsTranslations()) as Translations;

const { product } = toRefs(props);

const { showButton: showAddToCartButton } = useAddToCart(product);
const { getUrlPrefix } = useUrlResolver();

const imageElement = ref(null);
const { height } = useElementSize(imageElement);

const DEFAULT_THUMBNAIL_SIZE = 10;

function roundUp(num: number) {
  return num ? Math.ceil(num / 100) * 100 : DEFAULT_THUMBNAIL_SIZE;
}

const srcPath = computed(() => {
  return `${getSmallestThumbnailUrl(
    product.value?.cover?.media,
  )}?&height=${roundUp(height.value)}&fit=crop`;
});

const isInFuture = computed(() => {
  const fundingStartDate =
    product.value?.extensions?.cashlinkCampaignIdExtension?.cashlinkProduct
      ?.extensions?.fundingInformationExtension?.fundingStartDate;
  if (!fundingStartDate) return false;

  const now = new Date().getTime();
  const startDate = new Date(fundingStartDate).getTime();
  return startDate - now > 0;
});

const daysLeft = computed(() => {
  const fundingEndDate =
    product.value?.extensions?.cashlinkCampaignIdExtension?.cashlinkProduct
      ?.extensions?.fundingInformationExtension?.fundingEndDate;
  if (!fundingEndDate) {
    return 0;
  }

  const now = new Date();
  const endDate = new Date(fundingEndDate);
  const timeLeft = endDate.getTime() - now.getTime();
  return Math.ceil(timeLeft / (1000 * 3600 * 24));
});

const isMinimalLayout = ref(props.layoutType === "minimal");
</script>

<template>
  <v-card
    class="sw-product-card bg-white border-gray200"
    data-testid="product-box"
    rounded="lg"
    variant="outlined"
  >
    <!-- PRODUCT IMAGE -->
    <RouterLink :to="buildUrlPrefix(getProductRoute(product), getUrlPrefix())">
      <v-img
        :alt="getProductName({ product }) || ''"
        :aspect-ratio="3 / 2"
        :src="srcPath"
        cover
        data-testid="product-box-img"
        loading="lazy"
      />
    </RouterLink>

    <v-card-text class="pb-0">
      <!-- TAGS/BADGE/CHIPS -->
      <div v-if="!isMinimalLayout" class="mb-3" style="height: 28px">
        <ProductCardBadge
          v-for="(badge, index) in product?.extensions
            ?.cashlinkCampaignIdExtension?.cashlinkProduct?.badges"
          :key="badge.id"
          :badge="badge"
        />
      </div>

      <!-- PRODUCT BRANCH -->
      <div
        v-if="!isMinimalLayout"
        class="text-uppercase product__branch mb-2"
        style="height: 18px"
      >
        {{
          product?.extensions?.cashlinkCampaignIdExtension?.cashlinkProduct
            ?.branch?.name
        }}
      </div>

      <!-- PRODUCT TITLE -->
      <v-link
        :to="buildUrlPrefix(getProductRoute(product), getUrlPrefix())"
        data-testid="product-box-product-name-link"
        class="line-clamp-1 text-h3"
      >
        {{ getProductName({ product }) }}
      </v-link>

      <!-- PRODUCT DESCRIPTION -->
      <div
        class="line-clamp-3 text-body-1"
        style="height: 4.5rem"
        v-html="product.description"
      />

      <!-- PRODUCT INVESTMENT PROGRESS -->
      <FundingProgress :product="product" compact />
    </v-card-text>

    <!-- ACTIONS -->
    <v-card-actions v-if="!isInFuture" class="px-4">
      <v-list-item class="w-100 px-0">
        <template #prepend>
          <ProductAddToCartButton
            v-if="showAddToCartButton"
            :product="product"
            :rounded="false"
            variant="plain"
          />
          <v-btn
            v-else
            :append-icon="mdiArrowRight"
            :to="buildUrlPrefix(getProductRoute(product), getUrlPrefix())"
            class="px-0 text-body-1"
            type="button"
            variant="text"
          >
            {{ $t("product.detail") }}
          </v-btn>
        </template>

        <template v-if="daysLeft > 0" #append>
          <div class="justify-self-end">
            <v-icon
              :icon="mdiCalendarBlankOutline"
              class="mr-1 d-none d-sm-inline-flex"
            />
            {{ $t("product.daysLeft", daysLeft, { n: daysLeft }) }}
          </div>
        </template>
      </v-list-item>
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.product__branch {
  font-size: 0.75rem;
}

.border-gray200 {
  border-color: rgb(var(--v-theme-gray200));
}
</style>
